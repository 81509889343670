<template>
    <div>
        <div class="header-accordion collapsed" data-toggle="collapse" :data-target="`#collapse${type}`" aria-expanded="true">
            <div class="category-header">
                <div class="icon-left">
                    <icon-calendar-exclamation v-if="type == 'past'"/>
                    <icon-calendar-day v-else-if="type == 'today'"/>
                    <icon-calendar-week v-else-if="type == 'week'"/>
                    <icon-calendar-month v-else-if="type == 'month' || type == 'next_month'"/>
                    <icon-calendar-cross v-else-if="type == 'without_date'"/>
                </div>
                <div class="title">{{ $t(`task_list.accordion_title.${type}`) }}</div>
    
                <button class="arrow-collapse show" :class="{collapsed: !arrayData || !arrayData.length}" type="button" data-toggle="collapse" :data-target="`#collapse${type}`" aria-expanded="true">
                    <icon-arrow />
                </button>
            </div>
        </div>

        <div class="content-accordion collapse" :class="{show: arrayData && arrayData.length}" :id="`collapse${type}`">
            <template v-if="arrayData && arrayData.length">
                <div class="category-item" v-for="taskLog in arrayData">
                    <div class="checkbox-tbf-special" :class="{active: taskLog.done}" @click="toggleCheck( taskLog )">
                        <icon-check />
                    </div>
                    <div class="item-name" :class="{done: taskLog.done}" @click="openTaskType(taskLog)">
                        {{ taskLog.name }}
                    </div>
                    <div class="actions">
                        <div class="project column-task" v-if="(taskLog.project != null && taskLog.project) || (taskLog.task && taskLog.task.project != null && taskLog.task.project)">
                            <template v-if="taskLog.log_type == 'task'">
                                <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                                    {{ taskLog.project.name.split(' ').map(word => word[0]).slice(0, 2).join('') }}
                                    <template slot="popover">
                                        <div class="simple-text" v-html="taskLog.project.name"></div>
                                    </template>
                                </v-popover>
                            </template>
                            <template v-else-if="taskLog.log_type == 'task_log'">
                                <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}">
                                    {{ taskLog.task.project.name.split(' ').map(word => word[0]).slice(0, 2).join('') }}
                                    <template slot="popover">
                                        <div class="simple-text" v-html="taskLog.task.project.name"></div>
                                    </template>
                                </v-popover>
                            </template>
                        </div>
                        <div class="time column-task" v-if="type != 'without_date'">
                            <template v-if="['past', 'month', 'next_month'].includes(type)">{{ taskLog.date | moment('DD-MM') }}</template>
                            <template v-else-if="['week'].includes(type)">{{ taskLog.date | moment('dd') }}</template>
                            <template v-else-if="['today'].includes(type)">{{ $t('calendar.today') }}</template>
                        </div>
                        <div :class="`priority column-task ${taskLog.priority ? taskLog.priority : ''}`">
                            <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" :disabled="!taskLog.priority">
                                <icon-priority/>
                                <template slot="popover">
                                    <div class="simple-text">{{ taskLog.priority }}</div>
                                </template>
                            </v-popover>
                        </div>

                        <div class="responsible column-task">
                            <template v-if="taskLog.user">
                                <v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" :disabled="!taskLog.user" v-if="$auth.user().id != taskLog.user.id">
                                    <img class="user-avatar" :src="taskLog.user.avatar" />
        
                                    <template slot="popover">
                                        <div class="simple-text" v-if="taskLog.user">{{ taskLog.user.name }}</div>
                                    </template>
                                </v-popover>
                                <div class="text" v-else>{{ $t('dashboard.me')}}</div>
                            </template>
                            <div class="no-responsible" v-else>
                                <icon-user/>
                            </div>
                        </div>

                        <div class="settings column-task" @click="openTaskType(taskLog)" v-if="type == 'without_date'">
                            <icon-settings-gear/>
                        </div>
                        <div class="frequency column-task" v-else>
                            <template v-if="taskLog.frequency">
                                <icon-repeat />
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="category-item">
                    <div class="empty-message">{{ $t('task_list.empty_list') }}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
	import IconCalendarDay from '../../Icons/CalendarDay'
	import IconCalendarWeek from '../../Icons/CalendarWeek'
	import IconCalendarMonth from '../../Icons/CalendarMonth'
	import IconCalendarCross from '../../Icons/CalendarCross'
	import IconCalendarExclamation from '../../Icons/CalendarExclamation'
	import IconPriority from '../../Icons/Flag'
	import IconRepeat from '../../Icons/Repeat'
	import IconCheck from '../../Icons/Check'
	import IconSettingsGear from '../../Icons/SettingsGear'
	import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'

	export default {
		components: {
			IconCalendarDay,
			IconCalendarWeek,
			IconCalendarMonth,
			IconCalendarCross,
			IconCalendarExclamation,
			IconPriority,
			IconRepeat,
            IconCheck,
            IconSettingsGear,
            IconArrow,
            IconUser
		},
        props: {
            type: String,
            arrayData: Array
        },
		data() {
			return {
			}
		},
		methods: {
			openTaskType(task){
                switch (task.log_type) {
                    case 'responsibility':
                        this.$root.$emit('open_modal', 'show_responsibility', {
                            from: 'user',
                            userId: this.$auth.user().id,
                            userSlug: this.$auth.user().slug,
                            userRight: true,
                            id: task.id,
                            unique_id: task.unique_id,
                            date: moment(task.start).format('YYYY-MM-DD')
                        });
                        break;
                    case 'key_result':
                        this.$root.$emit('open_modal', 'show_task_key_result', {id: task.id} );
                        break;
                    default:
                        this.$root.$emit('open_modal', 'add_task', {crud: 'edit', taskEditId: task.parent_id, date: task.date}, false);
                        break;
                }
			},
            toggleCheck(task) {
                var done = task.done ? 0 : 1; 
                var newDate = '';

                if(!task.date) {
                    newDate = moment().format('YYYY-MM-DD')
                } else {
                    newDate = moment(task.date).format('YYYY-MM-DD');
                }

                var itemIndex = this.arrayData.findIndex(el => el.id == task.id && el.date == task.date);
                this.arrayData[itemIndex].done = done;

                if(task.log_type == 'key_result') {
                    axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/tasks/${task.id}`, { deadline: newDate, done: done, name: task.name })
                    .then(() => {
                        if(task.date){
                            this.$root.$emit('updateEventCalendar', {id: task.unique_id, date: task.date, className: done ? 'accomplished task' : 'unaacomplished task' })
                        }
                    })
                } else {
                    axios.post(`/tasks/${task.parent_id}/check`, { date: newDate, done: done, from_calendar: 1 })
                    .then(({data}) => {
                        if(data.data.task_log_id) {
                            this.arrayData[itemIndex].id = data.data.task_log_id;
                            this.arrayData[itemIndex].log_type = 'task_log';
                        }

                        if(task.date){
                            this.$root.$emit('updateEventCalendar', {id: task.unique_id, date: task.date, className: done ? 'accomplished task' : 'unaacomplished task' })
                        } else {
                            this.arrayData[itemIndex].date = newDate;
                        }
                        
					    this.$root.$emit('refreshTaskReport');
                    })
                }

            }
		}
	}
</script>